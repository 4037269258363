import { IFeatureState } from 'thunderbolt-feature-state'
import { ProtectedPagesState, LoginTypes, PagesMap, ProtectedPageMasterPageConfig } from './types'
import { IRouter, Router } from 'feature-router'
import { ISiteMembersApi, SiteMembersApiSymbol } from 'feature-site-members'
import { PasswordProtectedPageApiSymbol, IPasswordProtectedPageApi } from 'feature-password-protected-page'
import {
	ILogger,
	IAppWillMountHandler,
	FeatureStateSymbol,
	LoggerSymbol,
	MasterPageFeatureConfigSymbol,
} from '@wix/thunderbolt-symbols'
import { withDependencies, named, optional } from '@wix/thunderbolt-ioc'
import { name } from './symbols'

const protectedPagesLoginAndNavigate = (
	featureState: IFeatureState<ProtectedPagesState>,
	{ noPermissionsPageUrl }: ProtectedPageMasterPageConfig,
	router: IRouter,
	siteMembersApi: ISiteMembersApi,
	passwordProtectedPageApi: IPasswordProtectedPageApi,
	logger: ILogger
): IAppWillMountHandler => {
	const doSiteMembersLogin = async () => {
		if (siteMembersApi) {
			const result = await siteMembersApi.requestAuthentication({})
			if (result.success) {
				return siteMembersApi.authorizeMemberPagesByToken(result.token!)
			}
		}

		return {}
	}

	const doPasswordEnter = async (pageId: string) => {
		if (passwordProtectedPageApi) {
			return await passwordProtectedPageApi.promptPasswordDialog(pageId)
		}

		return {}
	}

	const doLogin = async (loginType: LoginTypes, pageId: string) => {
		return loginType === LoginTypes.SM ? doSiteMembersLogin() : doPasswordEnter(pageId)
	}

	const loginAndNavigate: ProtectedPagesState['loginAndNavigate'] = async (routeInfo, loginType) => {
		let url: string
		try {
			const pageId = routeInfo.pageId!
			const authorizedPagesMap: PagesMap = await doLogin(loginType, pageId)
			featureState.update((state) => ({
				...state,
				pagesMap: Object.assign(state.pagesMap, authorizedPagesMap),
			}))
			if (authorizedPagesMap[pageId]) {
				url = routeInfo.parsedUrl!.href
			} else {
				url = noPermissionsPageUrl
			}
		} catch (err) {
			logger.captureError(err, { tags: { feature: 'protectedPage' } })
			url = noPermissionsPageUrl
		}

		return router.navigate(url)
	}

	featureState.update(() => ({
		loginAndNavigate,
		pagesMap: {},
	}))

	return {
		appWillMount: async () => {},
	}
}

export const ProtectedPagesAppWillMountHandler = withDependencies(
	[
		named(FeatureStateSymbol, name),
		named(MasterPageFeatureConfigSymbol, name),
		Router,
		optional(SiteMembersApiSymbol),
		optional(PasswordProtectedPageApiSymbol),
		LoggerSymbol,
	],
	protectedPagesLoginAndNavigate
)
